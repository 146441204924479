import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const appNode = document.getElementById('root');

/**
 * basic portal component
 */
class PortalWrapper extends React.Component {
  /**
   * constructor
   * @param {Object} props props
   * @returns {undefined}
   */
  constructor(props) {
    super(props);
    this.portal = document.createElement('div');
  }

  /**
   * appends div element to app element
   * @returns {undefined}
   */
  componentDidMount() {
    appNode.appendChild(this.portal);
  }

  /**
   * removes div
   * @returns {undefined}
   */
  componentWillUnmount() {
    appNode.removeChild(this.portal);
  }

  /**
   * render
   * @returns {JSX} component
   */
  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.portal,
    );
  }
}

PortalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PortalWrapper;
