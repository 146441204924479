import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { restCall } from '../../rest/Rest';
import { required } from '../../common/utils';

class Leistung extends React.Component {
  constructor(props) {
    super(props);

    this.handleAntragsArtChange = this.handleAntragsArtChange.bind(this);
    this.state = {
      antragsArten: [],
      artBegehren: [],
    };
  }


  async componentDidMount() {
    await this.getAntragsArtFromBackend();
    await this.getArtBegehrenFromBackend();
  }


  async componentDidUpdate(prevProps) {
    const { values } = this.props;
    if (values !== prevProps.values
      && values.kg !== prevProps.values.kg) {
      await this.getAntragsArtFromBackend();
    }

    if (
      values !== prevProps.values
      && values.antragsArt !== prevProps.values.antragsArt
    ) {
      await this.getArtBegehrenFromBackend();
    }
  }

  async getArtBegehrenFromBackend() {
    const { values, mutators } = this.props;


    if (values && values.antragsArt && values.kg && values.kg.bundesLand) {
      let artBegehren = null;


      const restResult = await restCall('GET', `/lookup/artBegehren?bundesland=${values.kg.bundesLand}&antragsArt=${values.antragsArt.name}`);
      if (restResult.status === 200) {
        artBegehren = restResult.body;
      }
      this.setState(
        {
          artBegehren,
        }, () => {
          mutators.setValue('artDesBegehrens', artBegehren[0]);
        },
      );
    }
  }

  async getAntragsArtFromBackend() {
    const { values, mutators } = this.props;

    if (values && values.kg && values.kg.bundesLand) {
      let antragsArten = {};
      const restResult = await restCall('GET', `/lookup/antragsArt?bundesland=${values.kg.bundesLand}`);
      if (restResult.status === 200) {
        antragsArten = restResult.body;
      }
      this.setState(
        {
          antragsArten,
        }, () => {
          mutators.setValue('antragsArt', antragsArten[0]);
        },
      );
    }
  }

  handleAntragsArtChange(antragsArtValue) {
    const { mutators } = this.props;

    this.state.antragsArten.forEach((antragsart) => {
      if (antragsart.name === antragsArtValue) {
        mutators.setValue('antragsArt.lkz', antragsart.lkz);
        mutators.setValue('antragsArt.leistung', antragsart.leistung);
      }
    });

    mutators.setValue('antragsArt.name', antragsArtValue);


    return antragsArtValue;
  }

  render() {
    const { antragsArten, artBegehren } = this.state;

    return (
      <div>

        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md">
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
            <label htmlFor="antragsArt" className="required">Antragsart</label>
          </div>
          <div className="col-12 col-md-8">

            <Field
              id="antragsArt.name"
              name="antragsArt.name"
              component="select"
              type="text"
              placeholder="Antragsart"
              onChange={(e) => {
                this.handleAntragsArtChange(e.target.value);
              }}
              validate={required}
            >
              {({ input, meta }) => (
                <div>
                  <select
                    {...input}
                    className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`}
                    aria-label="Antragsart"
                  >

                    {
                    antragsArten && antragsArten.map(antragsart => (
                      <option
                        key={antragsart.name}
                        value={antragsart.name}
                      >
                        {antragsart.name}
                      </option>
                    ))
                  }
                  </select>
                </div>
              )}
            </Field>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md">
            <label htmlFor="lkz" className="required">Leistung</label>
          </div>
          <div className="col-12 col-md-8">
            <Field
              id="antragsArt.leistung"
              name="antragsArt.leistung"
              component="input"
              type="text"
              placeholder="Leistung"
              className="form-input"
              aria-label="Leistung"
              readOnly
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md">
            <label htmlFor="lkz" className="required">Leistungskennzeichen</label>
          </div>
          <div className="col-12 col-md-8">
            <Field
              aria-label="Leistungskennzeichen"
              id="antragsArt.lkz"
              name="antragsArt.lkz"
              component="input"
              type="text"
              placeholder="LKZ"
              className="form-input"
              readOnly
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md">
            <label htmlFor="artDesBegehrens" className="required">Art des Begehrens</label>
          </div>
          <div className="col-12 col-md-8">
            <Field id="artDesBegehrens" name="artDesBegehrens" component="select" className="form-input" validate={required}>
              {({ input, meta }) => (
                <select {...input} className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Art des Begehrens">
                  {
                artBegehren && artBegehren.map(artBegehrenElement => (
                  <option
                    key={artBegehrenElement}
                    value={artBegehrenElement}
                  >
                    {artBegehrenElement}
                  </option>
                ))
              }
                </select>
              )}
            </Field>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md">
            <label htmlFor="regelung">Landesspezifische Regelung</label>
          </div>
          <div className="col-12 col-md-8">
            <Field
              id="regelung"
              name="regelung"
              component="input"
              type="text"
              placeholder="Landesspezifische Regelung"
              className="form-input"
              aria-label="Landesspezifische Regelung"
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md top-aligned">
            <label className="top-aligned" htmlFor="bemerkung">Bemerkung</label>
          </div>
          <div className="col-12 col-md-8 ">
            <Field
              id="bemerkung"
              name="bemerkung"
              aria-label="Bemerkung"
              component="textarea"
              placeholder="Bemerkung – Zusatzinformation für die empfangende Behörde"
              className="form-input"
            />
          </div>
        </div>
      </div>
    );
  }
}


Leistung.defaultProps = {

};
Leistung.propTypes = {
  values: PropTypes.object.isRequired,
  mutators: PropTypes.func.isRequired,
};

export default Leistung;
