import React from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { restCall } from '../../rest/Rest';

function KG(props) {
  const { kg, mutators } = props;
  return (
    <div>
      <div className="form-group row">
        <div className="col-12 col-md-4 right-aligned-md">
          <label htmlFor="kgNr">Katastralgemeinde</label>
        </div>
        <div className="col-12 col-md-8">


          <Field name="kg">
            {({ input }) => (
              <div>
                {kg && <KGInput input={input} mutators={mutators} initialValue={kg} readOnly />}
              </div>
            )}
          </Field>
        </div>
      </div>
    </div>
  );
}

class KGInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {},
      options: [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    let kgs = null;
    const restResult = await restCall('GET', '/lookup/kg');
    if (restResult.status === 200) {
      kgs = restResult.body;
    }


    this.setState(
      {
        options: kgs,
      },
    );
  }

  handleChange(option) {
    const { input } = this.props;
    this.setState({
      value: option,
    });

    input.value = this.state.value;
    input.onChange(option);
  }

  render() {
    const { initialValue } = this.props;

    return (
      <>
        <Select
          options={this.state.options}
          onChange={this.handleChange}
          getOptionValue={option => option.kgNr}
          getOptionLabel={option => `${option.kgNr}  | ${option.name} `}
          defaultValue={initialValue}
          isDisabled
          aria-label="Katastralgemeinde"
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Bitte wählen"
        />
      </>

    );
  }
}

KG.propTypes = {
  kg: PropTypes.object.isRequired,
  mutators: PropTypes.object.isRequired,
};
export default KG;
