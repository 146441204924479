import React from 'react';
import { Field } from 'react-final-form';
import DatePicker from 'react-datepicker';
import Person from '../../common/Person/Person';
import EigentuemerList from './EigentuemerList';
import 'react-datepicker/dist/react-datepicker.css';
import { required } from '../../common/utils';
import GStNrList from '../GstNr/GstNrList';

export default class Urkunde extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { planDatum: null, auftragsDatum: null };
    this.handlePlanDatumChange = this.handlePlanDatumChange.bind(this);
    this.getValue = this.getValue.bind(this);
  }

  getValue(input) {
    const { auftragsDatum } = this.state;
    if (auftragsDatum == null) {
      this.setState({
        auftragsDatum: new Date(input),
      });
    }
  }

  handlePlanDatumChange(input) {
    const { mutators } = this.props;
    if (input) {
      mutators.setValue('planDatum', input);
    }
  }

  handleAuftragsDatumChange(input) {
    // eslint-disable-next-line react/prop-types
    const { mutators } = this.props;
    if (input) {
      this.setState({
        auftragsDatum: new Date(input),
      });

      mutators.setValue('auftragsDatum', input);
    }
  }

  render() {
    const { values, mutators } = this.props;
    return (
      <div>

        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md">
            <label htmlFor="gz" className="required">GZ</label>
          </div>
          <div className="col-12 col-md-8">
            <Field
              id="gz"
              name="gz"
              component="input"
              type="text"
              placeholder="GZ"
              className="form-input"
              validate={required}

            >
              {({ input, meta }) => (
                <div>
                  <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="GZ" />
                </div>
              )}

            </Field>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md">
            <label htmlFor="planDatum" className="required">Plandatum</label>
          </div>
          <div className="col-12 col-md-8">
            <Field
              name="planDatum"
              validate={required}
              render={({ input }) => (
                <DatePicker
                  className="form-input"
                  onChange={dateInput => this.handlePlanDatumChange(dateInput)}
                  dateFormat="dd.MM.yyyy"
                  selected={input.value ? new Date(input.value) : new Date()}
                  id="planDatum"
                />
              )}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md">
            <label className="required  " htmlFor="auftragsDatum">Auftragsdatum</label>
          </div>
          <div className="col-12 col-md-8">
            <Field
              name="auftragsDatum"
              validate={required}
              render={({ input }) => (
                <div>
                  {' '}
                  <DatePicker
                    id="auftragsDatum"
                    className="form-input"
                    onChange={dateInput => this.handleAuftragsDatumChange(dateInput)}
                    dateFormat="dd.MM.yyyy"
                    selected={input.value ? new Date(input.value) : new Date()}
                  />
                </div>
              )}
            />
          </div>
        </div>

        <hr />

        <GStNrList mutators={mutators} {...this.props} />
        <hr />

        {/* eslint-disable-next-line jsx-a11y/aria-role */}
        <Person role="planverfasser" label="Planverfasser" allowCompany={false} {...this.props} isEmailRequired />
        <hr />

        <div>
          {/* eslint-disable-next-line jsx-a11y/aria-role */}
          <Person role="bevollmaechtigter" label="Bevollmächtigter" allowCompany={false} planVerfasserIsBevollmaechtigter={values.planVerfasserIsBevollmaechtigter} {...this.props} isEmailRequired={false} />
        </div>

        <hr />

        <EigentuemerList mutators={mutators} {...this.props} />


      </div>
    );
  }
}
