import React from 'react';
import ztLogo from '../assets/zt_Logo.png';

function Header() {
  return (
    <div>
      <div className="header">
        <img
          alt="Link löschen"
          src={ztLogo}
        />
      </div>
      <div className="menu" />
    </div>
  );
}

export default Header;
