import { Field } from 'react-final-form';
import React from 'react';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));


export default function CountryField(props) {
  const countryObj = countries.getNames('de');

  const countryOptions = [];
  const initialValue = 'AUT';

  // eslint-disable-next-line no-restricted-syntax
  for (const [isoCode, countryName] of Object.entries(countryObj)) {
    const iso3Code = countries.alpha2ToAlpha3(isoCode);
    countryOptions.push({ iso3Code, countryName });

    // countryOptions.push(<option value={iso3Code}>{countryName}</option>);
  }

  countryOptions.sort((a, b) => a.countryName.localeCompare(b.countryName));

  return (
    <Field
      component="select"
      initialValue={initialValue}
      name={props.name}
      className="form-input"
      autoComplete="none"
      maxLength="3"
      validate={props.required}
      aria-label="Staat"
    >
      {' '}
      {
    countryOptions.map(option => <option value={option.iso3Code}>{option.countryName}</option>)
  }

    </Field>
  );
}
