import React from 'react';
import './style/Main.scss';
import AntragFormNew from '../Form/AntragFormNew';
import Header from '../Layout/Header';

function Main() {
  return (
    <div>
      <Header />
      <AntragFormNew />
    </div>
  );
}

export default Main;
