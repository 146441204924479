import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { required, validEMail } from '../utils';
import CountryField from '../CountryField';

function NatuerlichePerson(props) {
  const { role, isEmailRequired } = props;

  return (
    <fieldset>

      <div className="col-12 col-md-4 right-aligned-md">
        <legend>Anrede</legend>
      </div>

      <div className="col-12 col-md-8 cb-group">
        <label>

          <Field
            name={`${role}.anrede`}
            component="input"
            type="radio"
            aria-label="Anrede"
            value="Herr"
          />
          &nbsp;Herr
        </label>

        <label>
          <Field
            name={`${role}.anrede`}
            component="input"
            type="radio"
            value="Frau"
            aria-label="Anrede"
          />
          &nbsp;Frau
        </label>

        <label>
          <Field
            name={`${role}.anrede`}
            component="input"
            type="radio"
            value="unbekannt"
            aria-label="Anrede"
          />
          &nbsp;keine Angabe
        </label>
      </div>

      <div className="form-group row">
        <div className="col-12 col-md-4 right-aligned-md">
          <label htmlFor="titelVor">Titel vor</label>
        </div>
        <div className="col-12 col-md-3">
          <Field
            component="input"
            type="text"
            className="form-input"
            name={`${role}.titelVor`}
            aria-label="Titel vor"
          />
        </div>

        <div className="col-12 col-md-2 right-aligned-md">
          <label htmlFor="titelNach">Titel nach</label>
        </div>
        <div className="col-12 col-md-3">
          <Field
            component="input"
            className="form-input"
            type="text"
            aria-label="Titel nach"
            name={`${role}.titelNach`}
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-12 col-md-4 right-aligned-md">
          <label htmlFor="vorname" className="required">Vorname</label>
        </div>
        <div className="col-12 col-md-3">
          <Field name={`${role}.vorname`} validate={required}>
            {({ input, meta }) => (
              <div>
                <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Vorname" />
              </div>
            )}
          </Field>

        </div>

        <div className="col-12 col-md-2 right-aligned-md">
          <label htmlFor="nachname" className="required">Nachname</label>
        </div>
        <div className="col-12 col-md-3">
          <Field
            component="input"
            type="text"
            name={`${role}.nachname`}
            className="form-input"
            validate={required}
          >
            {({ input, meta }) => (
              <div>
                <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Nachname" />

              </div>
            )}
          </Field>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-12 col-md-4 right-aligned-md">
          <label htmlFor="nachname" className="required">Straße</label>
        </div>
        <div className="col-12 col-md-5">
          <Field
            component="input"
            type="text"
            validate={required}
            name={`${role}.adresse.strasse`}
            className="form-input"
          >
            {({ input, meta }) => (
              <div>
                <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Straße" />
              </div>
            )}
          </Field>
        </div>

        <div className="col-12 col-md-1 right-aligned-md ">
          <label htmlFor="hausnummer" className="required">Nr.</label>
        </div>
        <div className="col-12 col-md-2">
          <Field
            component="input"
            type="text"
            validate={required}
            name={`${role}.adresse.hausnummer`}
            className="form-input"
          >
            {({ input, meta }) => (
              <div>
                <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Hausnummer" />
              </div>
            )}
          </Field>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-12 col-md-4 right-aligned-md">
          <label htmlFor="nachname" className="required">Postleitzahl</label>
        </div>
        <div className="col-12 col-md-2">
          <Field
            component="input"
            type="text"
            validate={required}
            name={`${role}.adresse.plz`}
            className="form-input"
          >
            {({ input, meta }) => (
              <div>
                <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="PLZ" />
              </div>
            )}
          </Field>
        </div>

        <div className="col-12 col-md-1 right-aligned-md">
          <label htmlFor="ort" className="required">Ort</label>
        </div>
        <div className="col-12 col-md-5">
          <Field
            component="input"
            type="text"
            validate={required}
            name={`${role}.adresse.ort`}
            className="form-input"

          >
            {({ input, meta }) => (
              <div>
                <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Ort" />
              </div>
            )}
          </Field>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-12 col-md-4 right-aligned-md">
          <label htmlFor="nachname" className="required">Staat</label>
        </div>
        <div className="col-12 col-md-8">
          <CountryField name={`${role}.adresse.staat`} validate={required} />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-12 col-md-4 right-aligned-md">
          <label htmlFor="email" className={isEmailRequired && 'required'}>E-Mail</label>
        </div>
        <div className="col-12 col-md-8">
          <Field
            component="input"
            name={`${role}.email`}
            validate={validEMail && (isEmailRequired && required)}
          >
            {({ input, meta }) => (
              <div>
                <input {...input} type="email" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="E-Mail" />
              </div>
            )}
          </Field>
        </div>
      </div>
    </fieldset>
  );
}

NatuerlichePerson.defaultProps = {
  role: '',
};

NatuerlichePerson.propTypes = {
  role: PropTypes.string,
};

export default NatuerlichePerson;
