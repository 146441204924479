import React, { Component } from 'react';

class ModalDialog extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.onClose();
  }

  render() {
    const { header, message } = this.props;
    return (
      <div className="modal-wrapper">
        <div className="modal-content">
          {/* eslint-disable-next-line no-return-assign */}
          <h3 ref={subtitle => this.subtitle = subtitle}>{header}</h3>
          <div>
            {Array.isArray(message) ? message.map(paragraph => <p>{paragraph}</p>) : <p>{message}</p> }
          </div>
        </div>
        <div className="float-right">
          <button type="button" className="btn btn-primary btn-lg right-aligned-md" onClick={this.closeModal}>Schließen</button>
        </div>

      </div>
    );
  }
}

ModalDialog.defaultProps = {
  isOpen: false,
};

export default ModalDialog;
