/**
 * a simple (singleton) factory to generate/open modal dialogs
 */
class ModalFactory {
  /**
   * register function for the modalContainer component. this will only be called once
   * from the container
   * @param {Object} containerRef modalContainer ref
   * @returns {undefined}
   */
  registerContainer(containerRef) {
    this.containerRef = containerRef;
  }

  /**
   * opens a modal dialog for the passed modalClass (-> component)
   * @param {Object} modalClass class/component of the modal, which should be instantiated
   * @param {Object} props properties for the modal
   * @param {Object} params additional parameters. those params are returned
   * by a confirmation modal
   * @returns {undefined}
   */
  open(modalClass, props, params) {
    if (this.containerRef) {
      this.containerRef.show(modalClass, props, params);
    }
  }

  /**
   * opens a modal dialog and returns a promise. use async/await to retrieve the result of the modal
   * @param {Object} modalClass class/component of the modal, which should be instantiated
   * @param {Object} props properties for the modal
   * @param {Object} params additional parameters. those params are returned
   * by a confirmation modal
   * @returns {undefined}
   */
  openWithPromise(modalClass, props, params) {
    return this.containerRef.showWithPromise(modalClass, props, params);
  }
}

const modalFactory = new ModalFactory();

export default modalFactory;
