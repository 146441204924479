import filesaver from 'file-saver';

const host = '';

const withoutFileHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Accept': 'application/json', // eslint-disable-line quote-props
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache', // eslint-disable-line quote-props
};

export async function restCall(method, query, body = null, headers = withoutFileHeaders) {
  const url = host + query;
  let response;
  try {
    response = await fetch(url, {
      body: body ? JSON.stringify(body) : null,
      headers,
      method: method.toUpperCase(),
    });
  } catch (e) {
    // console.log(`${query} ${method} Fetch throwed: `, e);
    return ({
      error: true,
      errorInfo: 'FETCH_THROWED',
      status: null,
      body: e,
    });
  }

  let json;

  try {
    if (response.headers.get('Content-Type').startsWith('application/json')) {
      json = await response.json();
    } else if (response.headers.get('Content-Type').startsWith('application/pdf')) {
      const blob = await response.blob();
      filesaver.saveAs(blob, 'antrag.pdf');
    } else {
      json = response;
    }
  } catch (e) {
    json = null;
  }

  const { status } = response;

  // //console.log(`${query} ${method} Response received status code: ${status}`);
  // //console.log(JSON.stringify(json));

  if (status === 200) {
    return ({
      error: false,
      status,
      body: json,
    });
  }

  return ({
    error: true,
    errorInfo: 'WRONG_STATUS',
    status,
    body: json,
  });
}
