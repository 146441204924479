import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { required } from '../../common/utils';

function GStNr(props) {
  const { name, index } = props;
  const regexGStNr = /[.]?[0-9]{1,5}([/]{1}[0-9]{1,4})?/;
  const validGStNr = value => (!regexGStNr.test(value) ? 'Ungültige GStNr' : '');


  return (
    <fieldset className="form-group row">

      <div className="col-12 col-md-4 right-aligned-md">
        <label htmlFor={name} className="required">
          GStNr.
          {index + 1}
        </label>
      </div>

      <div className="col-3 ">
        <Field
          component="input"
          name={name}
          id={name}
          validate={validGStNr || required}
        >
          {({ input, meta }) => (
            <div>
              <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="GStNr." />
            </div>
          )}
        </Field>
      </div>

      {
        index > 0
        && (
        <button
          type="button"
          onClick={() => props.onRemove(index)}
          className="icon btn ml-5"
        >
          <FontAwesomeIcon icon={faTimesCircle} />
          &nbsp;Löschen
        </button>
        )
      }

    </fieldset>
  );
}

GStNr.defaultProps = {
  name: '',
  index: 0,
};

GStNr.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
};

export default GStNr;
