import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { required, validEMail } from './utils';
import CountryField from './CountryField';

// eslint-disable-next-line react/prefer-stateless-function
class Unternehmen extends React.Component {
  render() {
    const { label, role } = this.props;
    return (
      <div>
        <fieldset>
          <legend>{label}</legend>

          <div className="form-group row">
            <div className="col-12 col-md-4 right-aligned-md">
              <label htmlFor="name" className="required">Name</label>
            </div>
            <div className="col-12 col-md-8">
              <Field
                component="input"
                type="text"
                name={`${role}.companyName`}
                className="form-input"
                validate={required}

              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Firmenname" />
                  </div>
                )}
              </Field>


            </div>
          </div>

          <div className="form-group row">
            <div className="col-12 col-md-4 right-aligned-md">
              <label htmlFor="rechtsform">Rechtsform</label>
            </div>
            <div className="col-12 col-md-8">
              <Field
                component="input"
                type="text"
                name={`${role}.rechtsForm`}
                className="form-input"
                aria-label="Rechtsform"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-12 col-md-4 right-aligned-md">
              <label htmlFor="verwaltungseinheit">Verwaltungseinheit</label>
            </div>
            <div className="col-12 col-md-8">
              <Field
                component="input"
                type="text"
                name={`${role}.verwaltungsEinheit`}
                className="form-input"
                aria-label="Verwaltungseinheit"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-12 col-md-4 right-aligned-md">
              <label htmlFor="nachname" className="required">Straße</label>
            </div>
            <div className="col-5">
              <Field
                component="input"
                type="text"
                name={`${role}.adresse.strasse`}
                className="form-input"
                validate={required}
              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Straße" />
                  </div>
                )}
              </Field>
            </div>

            <div className="col-1 right-aligned-md">
              <label htmlFor="hausnummer" className="required">Nr.</label>
            </div>
            <div className="col-2">
              <Field
                component="input"
                type="text"
                name={`${role}.adresse.hausnummer`}
                className="form-input"
                validate={required}
              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Nummer" />
                  </div>
                )}
              </Field>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-12 col-md-4 right-aligned-md">
              <label htmlFor="nachname" className="required">Postleitzahl</label>
            </div>
            <div className="col-2">
              <Field
                component="input"
                type="text"
                name={`${role}.adresse.plz`}
                className="form-input"
                validate={required}

              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="PLZ" />
                  </div>
                )}
              </Field>
            </div>

            <div className="col-1 right-aligned-md">
              <label htmlFor="ort" className="required">Ort</label>
            </div>
            <div className="col-5">
              <Field
                component="input"
                type="text"
                name={`${role}.adresse.ort`}
                className="form-input"
                validate={required}

              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Ort" />
                  </div>
                )}
              </Field>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-12 col-md-4 right-aligned-md">
              <label htmlFor="staat" className="required">Staat</label>
            </div>
            <div className="col-12 col-md-8">
              <CountryField name={`${role}.adresse.staat`} validate={required} />

            </div>
          </div>

          <div className="form-group row">
            <div className="col-12 col-md-4 right-aligned-md">
              <label htmlFor="email">E-Mail</label>
            </div>
            <div className="col-12 col-md-8">
              <Field
                component="input"
                type="text"
                name={`${role}.adresse.email`}
                className="form-input"
                validate={validEMail}

              >
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="E-Mail" />
                  </div>
                )}
              </Field>
            </div>
          </div>

        </fieldset>
      </div>
    );
  }
}


Unternehmen.defaultProps = {
  label: '',
  role: '',
};

Unternehmen.propTypes = {
  label: PropTypes.string,
  role: PropTypes.string,
};

export default Unternehmen;
