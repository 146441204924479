import React from 'react';
import Loader from 'react-loader-spinner';

export default function SpinningWheel() {
  return (
    <div className="modal-overlay">
      <div className="spinning-wheel">
        <Loader
          type="Oval"
          color="#aaa"
          height="50"
          width="50"
        />
      </div>
    </div>
  );
}
