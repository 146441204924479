import React from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import queryString from 'query-string';
import createDecorator from 'final-form-focus';
import KG from './KG/KG';
import Leistung from './Leistung/Leistung';
import Behoerde from './Behoerde/Behoerde';
import Urkunde from './Urkunde/Urkunde';
import 'react-datepicker/dist/react-datepicker.css';
import { restCall } from '../rest/Rest';
import ModalDialog from './ModalDialog';
import SpinningWheel from '../common/SpinningWheel';
import ModalContainer from '../common/Modal/ModalContainer';
import modalFactory from '../common/Modal/modalFactory';

const qs = queryString.parse(window.location.search);
const { urkaToken } = qs;
const focusOnError = createDecorator();
const setValue = ([field, value], state, { changeValue }) => {
  changeValue(state, field, () => value);
};

class AntragForm extends React.Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line react/no-unused-state
    this.state = { antragAngenommen: false, antragInProgress: false, antrag: {} };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.formValid = this.formValid.bind(this);
  }

  async componentDidMount() {
    const antrag = await this.requestContext();
    this.setState({
      antrag,
    });
  }


  handleSubmit = async (values) => {
    console.log('handle Submit');
    this.setState({
      antragInProgress: true,
    });
    if (values.createPdf) {
      await restCall('POST', `/antrag/pdf?urkaToken=${urkaToken}`, values, { 'Content-Type': 'application/json' });
    } else {
      const response = await restCall('POST', `/antrag/send?urkaToken=${urkaToken}`, values, { 'Content-Type': 'application/json' });
      if (response && response.body && response.body.success) {
        modalFactory.open(
          ModalDialog,
          {
            header: 'Antrag korrekt versendet',
            message: ['Der elektronische Antrag wurde von der Antragsplattform übernommen:', `Vorgangsnummer: ${response.body.antragBehoerdenId}`, ' Es wurde eine Bestätigung per E-Mail versandt.'],
          },
        );
      } else if (response && response.body && !response.body.success) {
        let message;
        switch (response.body.errorType) {
          case 'VALIDATION':
            message = 'Es ist ein Fehler bei der Validierung aufgetreten.'; break;
          case 'PDF_GENERATION':
            message = 'Es ist ein Fehler bei der PDF-Generierung aufgetreten.'; break;
          case 'UNSPECIFIED':
            message = 'Es ist ein Fehler  aufgetreten.'; break;
          default:
            message = 'Es ist ein Fehler aufgetreten.';
        }
        modalFactory.open(
          ModalDialog,
          {
            header: 'Es ist ein Fehler aufgetreten',
            message,
          },
        );
      }
    }
    this.setState({
      antragInProgress: false,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async requestContext() {
    let antrag = null;
    const restResult = await restCall('GET', `/context/get?urkaToken=${urkaToken}`);
    if (restResult.status === 200) {
      antrag = restResult.body.antragDto;
      if (restResult.body.antragDto.eigentuemer === null) {
        restResult.body.antragDto.eigentuemer = [null];
      }


      // eslint-disable-next-line no-return-assign,no-param-reassign,no-nested-ternary
      restResult.body.antragDto.eigentuemer.map(val => val.anrede = val.anrede === 'MALE' ? 'Herr' : val.anrede === 'FEMALE' ? 'Frau' : null);


      if (restResult.body.antragDto.planverfasser.anrede === 'MALE') {
        restResult.body.antragDto.planverfasser.anrede = 'Herr';
      }

      if (restResult.body.antragDto.planverfasser.anrede === 'FEMALE') {
        restResult.body.antragDto.planverfasser.anrede = 'Frau';
      }
      if (restResult.body.antragDto.planverfasser.adresse.staat === null) {
        restResult.body.antragDto.planverfasser.adresse.staat = 'AUT';
      }
    }
    return antrag;
  }

  // eslint-disable-next-line class-methods-use-this
  formValid(values) {
    if (values.eigentuemer == null) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <div className="wrapper">
        <ModalContainer />
        {this.state.antragInProgress && (<SpinningWheel />)}

        <Form
          onSubmit={this.handleSubmit}
          mutators={{
            ...arrayMutators,
            setValue,
          }}
          decorators={[focusOnError]}
          initialValues={this.state.antrag}
          render={({
            handleSubmit,
            form,
            form: {
              // eslint-disable-next-line no-shadow
              mutators: { push, pop, setValue },
            },
            pristine,
            invalid,
            decorators,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <KG kg={values.kg} mutators={{ setValue }} />
              <hr />
              <Leistung values={values} mutators={{ setValue, push, pop }} bundesland={this.state.bundesland} />
              <hr />
              <Behoerde values={values} decorators={decorators} mutators={{ setValue }} />
              <hr />
              <Urkunde values={values} mutators={{ setValue, push, pop }} />
              <hr />

              <div className="form-group row">
                <div className="col-4" />
                <div className="col-12 col-md-8 right-aligned-md">
                  <button
                    className="btn btn-lg"
                    type="submit"
                    disabled={pristine || invalid}
                    onClick={() => {
                      form.change('createPdf', true);
                    }}
                  >
                    Antrag anzeigen
                  </button>
                  <button
                    className="btn btn-primary btn-lg"
                    type="submit"
                    onClick={() => {
                      form.change('createPdf', false);
                    }}
                  >
                    Antrag abschicken
                  </button>
                </div>
              </div>

              {/* <div style={{position: "absolute", right: "100px", top: "130px"}}> */}
              {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              {/* </div> */}
            </form>
          )
          }
        />

      </div>
    );
  }
}


export default AntragForm;
