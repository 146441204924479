import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { restCall } from '../../rest/Rest';
import { required } from '../../common/utils';

class Behoerde extends React.Component {
  async componentDidMount() {
    await this.getBehoerdeFromBackend();
  }

  async componentDidUpdate(prevProps) {
    const { values } = this.props;

    if (
      values !== prevProps.values
      && (values.kg !== prevProps.values.kg
      || values.antragsArt !== prevProps.values.antragsArt)
    ) {
      await this.getBehoerdeFromBackend();
    }
  }

  async getBehoerdeFromBackend() {
    const { values, mutators } = this.props;
    console.log('behoerde');
    console.log(values);
    console.log(values.antragsArt);
    if (values && values.kg && values.kg.kgNr && values.antragsArt) {
      const restResult = await restCall('GET', `/lookup/behoerde?kgnr=${values.kg.kgNr}&antragsArt=${values.antragsArt.name}`);
      if (restResult.status === 200) {
        mutators.setValue('behoerde.name', restResult.body.name);
        mutators.setValue('behoerde.vkz', restResult.body.vkz);
      }
    }
  }

  render() {
    return (
      <div>
        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md">
            <label htmlFor="behoerde" className="required">Behörde</label>
          </div>
          <div className="col-12 col-md-8">
            <Field
              name="behoerde.name"
              id="behoerde.name"
              component="input"
              className="form-input"
              validate={required}
            >
              {({ input, meta }) => (
                <div>
                  <input {...input} type="text" readOnly className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="Behörde" />
                </div>
              )}
            </Field>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12 col-md-4 right-aligned-md">
            <label htmlFor="behoerde.vkz" className="required">Verwaltungskennzeichen</label>
          </div>
          <div className="col-12 col-md-8">
            <Field
              id="behoerde.vkz"
              name="behoerde.vkz"
              component="input"
              type="text"
              placeholder="VKZ"
              className="form-input"
              readOnly
              aria-label="Verwaltungskennzeichen"
              validate={required}
            >
              {({ input, meta }) => (
                <div>
                  <input {...input} type="text" readOnly className={`form-input ${meta.error && meta.touched ? 'form-input-invalid' : ''}`} aria-label="E-Mail" />
                </div>
              )}
            </Field>
          </div>
        </div>

      </div>
    );
  }
}

Behoerde.defaultProps = {
  values: null,
  mutators: () => {},
};

Behoerde.propTypes = {
  values: PropTypes.object,
  mutators: PropTypes.func,
};

export default Behoerde;
