import React from 'react';
import PropTypes from 'prop-types';

/**
 * simple component that renders a portal/modal/overlay backdrop
 * @param {Object} props props
 * @returns {JSX} component jsx
 */
function PortalBackdrop(props) {
  const { onBackdropClick } = props;
  /* eslint-disable */
  return (
    <div
      role="button"
      id="__test_portal_backdrop"
      className="portal-backdrop"
      key="portalBackdrop"
      tabIndex="-1"
      onClick={onBackdropClick}
    ></div>
  );
}

PortalBackdrop.defaultProps = {
  onBackdropClick: null,
};

PortalBackdrop.propTypes = {
  onBackdropClick: PropTypes.func,
};

export default PortalBackdrop;
