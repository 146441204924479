/**
 * converts a binary string into a uint8Array
 * @param {String} str binary string
 * @returns {Uint8Array} uint8array
 */
export function convertString2Bytes(str) {
  const bytes = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i += 1) {
    bytes[i] = str.charCodeAt(i);
  }
  return bytes;
}


export const required = value => (value ? undefined : 'Pflichtfeld');
export const validEMail = (value) => {
  const regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  return !regexEmail.test(value) && value && value.length > 0 ? 'Ungültige E-Mail-Adresse' : '';
};
