import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Person from '../../common/Person/Person';

import { required } from '../../common/utils';

// eslint-disable-next-line react/prefer-stateless-function
export default class EigentuemerList extends React.Component {
  render() {
    const { mutators } = this.props;
    return (
      <div>
        <div className="form-group row">

          <div className="col-4" />
          <div className="col-12 col-md-8">

            <button
              type="button"
              className="btn"
              onClick={() => mutators.push('eigentuemer', undefined)}
            >
              Eigentümer hinzufügen
            </button>
          </div>
        </div>
        <hr />
        <FieldArray id="eigentuemer" name="eigentuemer" validate={required}>
          {({ fields }) => fields.map((name, index) => (
            <div>

              <div key={name} className="form-group row">
                <div className="col-12 col-md-4 right-aligned-md">
                  <legend className="legend">
                    Eigentümer
                    {' '}
                    {index + 1}
                  </legend>
                </div>
                <div className="col-12 col-md-8">

                  <button
                    type="button"
                    onClick={() => fields.remove(index)}
                    className="icon btn"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                    &nbsp;Löschen
                  </button>

                </div>
              </div>
              <Person role={name} allowCompany {...this.props} isEmailRequired={false} />
            </div>

          ))
          }
        </FieldArray>
      </div>
    );
  }
}
