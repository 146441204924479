import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import GStNr from './GStNr';

export default class GstNrList extends React.Component {
  componentDidMount() {
    this.props.mutators.push('grundstuecksNummern', undefined);
  }

  render() {
    const { mutators } = this.props;

    return (
      <div>
        <div className="form-group row">

          <div className="col-12 col-md-4 right-aligned-md required">Grundstücksnummer</div>
          <div className="col-12 col-md-8">

            <button
              type="button"
              className="btn"
              onClick={() => mutators.push('grundstuecksNummern', undefined)}
            >
              Grundstücksnummer hinzufügen
            </button>
          </div>
        </div>
        <GStNr name="grundstuecksNummern[0]" index={0} />
        <FieldArray name="grundstuecksNummern">
          {
            ({ fields }) => fields.map((name, j) => (
              j > 0 && <GStNr name={name} index={j} onRemove={index => fields.remove(index)} />
            ))
          }

        </FieldArray>
      </div>
    );
  }
}
